import React from "react";

const Icon_nav_arrow_back = ({
//   style = {},
  fill = "#000",
//   width = "100%",
  className = "",
  viewBox = "0 0 18 20"
}) => (
	<svg 
		className={`svg-icon ${className || ""}`}
		xmlns="http://www.w3.org/2000/svg" 
		viewBox={viewBox}
	>
		<g id="Symbols" transform="translate(-7 -6)">
			<g id="_32dp-Icon_Navigation_Arrow-Down">
				<path 
					id="arrow" 
					d="M16,6a.987.987,0,0,0-1,1V22.594L8.719,16.281a1.017,1.017,0,0,0-1.437,1.438l8,8a1.059,1.059,0,0,0,1.438,0l8-8A.963.963,0,0,0,25,17a.987.987,0,0,0-1-1,.961.961,0,0,0-.719.281L17,22.594V7A.987.987,0,0,0,16,6Z" 
					fill={fill}
					// fill-rule="evenodd"
				/>
			</g>
		</g>
	</svg>
);

/*
<svg
    width={width}
    style={style}
    height={width}
    viewBox={viewBox}
    xmlns="http://www.w3.org/2000/svg"
    className={`svg-icon ${className || ""}`}
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <path
      fill={fill}
      d="M15 18.5l-5-5.7 1.4-1.3 3.6 4.1 3.6-4.1 1.4 1.3-5 5.7z"
    />
  </svg>
  */

export default Icon_nav_arrow_back;
