import React from 'react';
import { hot } from "react-hot-loader";

// import './App.scss';

import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
// import { HashRouter as Router, Route, Switch, Redirect } from "react-router-dom";
import Home from "./pages/Home/Home";
import Impressum from "./pages/Impressum/Impressum";

import store from './store/store';
import { Provider } from 'react-redux';


class DebugRouter extends Router 
{
	constructor(props)
	{
		super(props);
		// console.log('initial history is: ', JSON.stringify(this.history, null,2))
		this.history.listen((location, action)=>{
			// console.log(`The current URL is ${location.pathname}${location.search}${location.hash}`)
			// console.log(`The last navigation action was ${action}`, JSON.stringify(this.history, null,2));
			// console.log(`The last navigation action was ${action}`, this.history);
		});
	}
}


class App extends React.Component 
{
	constructor(props) 
	{
		super(props);
		this.state = {};

		this.navElements = null;
		this.navContainers = null;
	}

	// componentDidMount() 
	// {
	// 	ReactGA.initialize("UA-146909304-2");
	// 	ReactGA.set({ anonymizeIp: true });
	// 	ReactGA.pageview("/home");
	// }

	render() {
		return (
			<Provider store={store}>
				<DebugRouter>
					<div id="app">
						<Switch>
							<Route exact={true} path="/" render={props => <Home {...props}></Home>} />
							<Route exact={true} path="/impressum" render={props => <Impressum {...props}></Impressum>} /> {/* https://www.andreasreiterer.at/fix-browserrouter-on-apache/ */}
							<Route render={props => <Home {...props}></Home>} />
						</Switch>
					</div>
				</DebugRouter>
			</Provider>
		);
	}
}

export default hot(module)(App);
