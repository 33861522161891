import React, { Component } from 'react'
// import PropTypes from "prop-types"
import './HighlightsSlider.scss'

// import ScrollOut from "scroll-out"
import ScrollOutUtils from "../../classes/ScrollOutUtils"
import ModuleWrapper from "../ModuleWrapper/ModuleWrapper"
import Slider from "react-slick"
import HighlightItem from "./HighlightItem"

import IconNavArrowForward from "../../assets/icon_nav_arrow_forward_20x18"
import IconNavArrowBack from "../../assets/icon_nav_arrow_back_20x18"

function ArrowNext(props) 
{
	const { className, style, onClick } = props;
	return (
		<div
			className={className}
			style={{ ...style, display: "block" }}
			onClick={onClick}
		>
			<IconNavArrowForward />
		</div>
	);
}
  
function ArrowPrev(props) 
{
	const { className, style, onClick } = props;
	return (
		<div
			className={className}
			style={{ ...style, display: "block" }}
			onClick={onClick}
		>
			<IconNavArrowBack />
		</div>
	);
}


class Slide extends Component
{
	static numItemsPerSlide = 3

	constructor(props) 
	{
		super(props)
		this.state = {}
	}

	componentDidMount()
	{
		ScrollOutUtils.init()
		// ScrollOut({
		// 	threshold: 0.5,
		// 	once: true,
		// });
	}

	getItems()
	{
		let items = []

		let startIdx = this.props.startIdx
		let children = this.props.children || []
		let itemsPerSlides = this.props.itemsPerSlides 
		let lastIdx = Math.min( startIdx + itemsPerSlides, children.length )
		
		for(let idx = startIdx; idx < lastIdx; idx++)
		{
			items.push( <HighlightItem key={`HighlightsItem${idx}`} itemIdx={idx} item={children[idx]} /> )
		}

		return items
	}

	render() 
	{
		let items = this.getItems()
		return (
			<div className="slide">
				{items}
			</div>
		)
	}
}


class HighlightsSlider extends Component 
{
	constructor(props) 
	{
		super(props)
		this.state = {
			isMobile: false
		}
		this.sliderRef = React.createRef()
		this.old_width = null
	}

	componentDidMount()
	{
		window.addEventListener('resize', this.onWindowResize)
		this.onWindowResize()
	}

	componentWillUnmount()
	{
		window.removeEventListener('resize', this.onWindowResize)
	}

	onWindowResize = (event) => {

		let current_width = ( this.sliderRef.current 
			? this.sliderRef.current.offsetWidth
			: window.outerWidth
		)
		
		if ( this.old_width === null )
		{
			this.old_width = current_width
			this.setState( {isMobile: (current_width <= 640) } )
			// console.log(">>>>>>>>>>>>> width status >> isMobile", this.isMobile)
			return
		}

		// let w = window.innerWidth
		// let h = window.innerHeight
		// console.log(`>>>>>>>>>>>> CTAElement >> onWindowResize :: ${w} x ${h}`)
		// console.log(">>>>>>>>>> CTAElement >> onWindowResize :: moduleContainerRef.height", this.moduleContainerRef.current.offsetHeight)

		if ( this.old_width>640 && current_width<=640 )
		{
			this.old_width = current_width
			this.setState( {isMobile: true } )
			// console.log(">>>>>>>>>>>>> enter mobile")
		}
		else if ( this.old_width<=640 && current_width>640 )
		{
			this.old_width = current_width
			this.setState( {isMobile: false } )
			// console.log(">>>>>>>>>>>>> enter desktop")
		}
	}

	callback_afterChange(idx) 
	{
		// idx += 1;
		// this.setState({
		// 	currentSlide: idx,
		// });
	}

	getSlides()
	{
		let slides = []

		if ( this.isMobile === null ) 
		{
			this.onWindowResize()	// init this.state.isMobile flag
		}

		let itemsPerSlides = ( this.state.isMobile 
			? 1
			: Slide.numItemsPerSlide
		)

		let children = this.props.children || []
		for (let idx = 0, keyIdx = 0; idx < children.length; idx+=itemsPerSlides, keyIdx++)
		{
			slides.push( 
				<Slide key={`Slide${keyIdx}`} itemsPerSlides={itemsPerSlides} startIdx={idx} children={children} /> 
			)
		}

		return slides
	}

	render() 
	{
		// console.log(">>>>>>>>>> HighlightsSlider :: this.props=", this.props)
		
		let settings = {
			dots: false,
			infinite: false,
			speed: 500,
			slidesToShow: 1,
			slidesToScroll: 1,
			arrows: true,
			initialSlide: 0,
			nextArrow: <ArrowNext />,
      		prevArrow: <ArrowPrev />,
			// afterChange: function(index) {
			// 	console.log(`Slider Changed to: ${index + 1}`);
			// },
			afterChange: this.callback_afterChange.bind(this),
		}

		let slides = this.getSlides()

		return (
			<ModuleWrapper className="HighlightsSlider" {... this.props}>
				<div className="root-container" ref={this.sliderRef} data-scroll>
					<Slider {...settings}>
						{slides}
					</Slider>
				</div>
			</ModuleWrapper>
		)
	}
}

// HighlightsSlider.defaultProps = {
// 	name: "",
// 	embedded: false,
// 	secondary: false,
// }

// HighlightsSlider.propTypes = {
// 	name: PropTypes.string,
// 	embedded: PropTypes.bool,
// 	secondary: PropTypes.bool,
// }

export default HighlightsSlider
