
import url from 'url'

const local_images = require.context('../assets', true)

class PathUtils
{
	// constructor() {}

	resolveImagePath(imgPath)
	{
		const parts = url.parse(imgPath)
		if ( typeof(parts.protocol)==="undefined" || parts.protocol===null || parts.protocol.length<=0 )
		{
			try
			{
				return local_images(imgPath).default
			}
			catch (error)
			{
				console.log(`%cError: File "${imgPath}" not found!`, "color: #f00")
				return ""
			}
		}
		return imgPath
	}
}

export default PathUtils