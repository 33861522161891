import React, { Component } from 'react'
import './Footer.scss'

import ComponentUtils from "../../classes/ComponentUtils"
import ModuleWrapper from "../ModuleWrapper/ModuleWrapper"
// import MenuItem from "../MenuItem/MenuItem"


class Footer extends Component 
{
	constructor(props) 
	{
		super(props)
		this.state = {}
	}

	render() 
	{
		// console.log(">>>>>>>>>> Footer :: this.props=", this.props)

		const componentUtils = new ComponentUtils()
		let children = componentUtils.getMenuItems(this.props, (this.props.name | 'Footer'))

		return (
			<ModuleWrapper className="Footer" {... this.props}>
				<ul className="horizontal-list">
					{children.map(each => <li key={`FooterMenuItem${Math.round(Math.random() * 100000)}`}>{each}</li>)}
				</ul>
			</ModuleWrapper>
		)
	}
}

// Footer.defaultProps = {
//     name: "",
//     primary: false,
//     secondary: false,
// }
//
// Footer.propTypes = {
//     name: PropTypes.string,
//     primary: PropTypes.bool,
//     secondary: PropTypes.bool,
// }

export default Footer
