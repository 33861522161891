import React, { Component } from "react"
import classnames from "classnames"
// import PropTypes from "prop-types"		// ... falls .propTypes (siehe am Ende der Datei) verwendet werden

import './NavigationBar.scss'

// import { goToTop, removeHash } from 'react-scrollable-anchor'
import ComponentUtils from "../../classes/ComponentUtils"
import ScrollUtils from "../../classes/ScrollUtils"
import ModuleWrapper from "../ModuleWrapper/ModuleWrapper"

import image_DBLogo from "../../assets/DB_logo_red_rgb_100px.svg"
// import icon_mobileMenu_open from "../../assets/icon-nav-open.svg"
import IconMobileMenuOpen from "../../assets/icon-nav-open"
import icon_mobileMenu_close from "../../assets/icon-nav-close.svg"
import IconMobileMenuContact from "../../assets/icon-nav-contact"

const CLASS_MENU_OPEN = "menu--open"
const CLASS_MENU_CLOSE_ANIM = "close--anim"
const CLASS_MENU_ANIM_FLAG = "animated"

class NavigationBar extends Component 
{
	constructor(props) 
	{
		super(props);

		this.state = {
			prevScrollPos: window.pageYOffset,
			visible: true
		};
		this.scrollUtils = new ScrollUtils()
		this.menuContainerRef = React.createRef()
		this.logoRef = React.createRef()
		this.mobileMenuButtonRef = React.createRef()
	}

	/*
	componentDidMount() 
	{
		window.addEventListener("scroll", this.handleScroll)
	}

	componentWillUnmount() 
	{
		window.removeEventListener("scroll", this.handleScroll)
	}

	handleScroll = () => {

		let wrapperElem = this.menuContainerRef.parentElement
		if ( wrapperElem.classList.contains(CLASS_MENU_OPEN) ) return		// wenn mobile Ansicht aktiv und Menü offen, dann NavigationBar nicht ausblenden

		const { prevScrollPos } = this.state

		const currentScrollPos = window.pageYOffset
		let visible = ( prevScrollPos > currentScrollPos )
		if ( prevScrollPos === currentScrollPos )
		{
			// wenn prevScrollPos === currentScrollPos, dann den vorherigen Wert von visible übernehmen,
			// sonst wird das NavBar versteckt, obwohl man nach oben gescrollt hat (kommt z.B. auf 
			// dem iPhone vor, wenn man nach ganz oben scrollt und der Gummiband-Effekt aktiviert wird oder
			// auf dem IE 11, egal in welcher richtung gescrollt wird)
			visible = this.state.visible
		}

		this.setState({
			prevScrollPos: currentScrollPos,
			visible
		})
	}
	*/

	__mobile_close_menu = (wrapperElem, onlyWhenOpen) => {

		if ( !wrapperElem.classList.contains(CLASS_MENU_OPEN) ) 
		{
			return
		}

		wrapperElem.classList.add(CLASS_MENU_CLOSE_ANIM, CLASS_MENU_ANIM_FLAG)
		setTimeout(() => { wrapperElem.classList.remove(CLASS_MENU_OPEN, CLASS_MENU_CLOSE_ANIM, CLASS_MENU_ANIM_FLAG) }, 350)	// animation duration = 0.25
	}

	onClick_mobileMenuButton = (e) => {

		let wrapperElem = this.menuContainerRef.parentElement

		if ( wrapperElem.classList.contains(CLASS_MENU_ANIM_FLAG) ) return;		// no action during animation

		if ( !wrapperElem.classList.contains(CLASS_MENU_OPEN) )
		{
			//---------------------------------- open menu
			wrapperElem.classList.add(CLASS_MENU_OPEN, CLASS_MENU_ANIM_FLAG)
			setTimeout(() => { wrapperElem.classList.remove(CLASS_MENU_ANIM_FLAG) }, 350)	// animation duration = 0.25
		}
		else
		{
			//---------------------------------- close menu
			// wrapperElem.classList.add(CLASS_MENU_CLOSE_ANIM, CLASS_MENU_ANIM_FLAG)
			// setTimeout(() => { wrapperElem.classList.remove(CLASS_MENU_OPEN, CLASS_MENU_CLOSE_ANIM, CLASS_MENU_ANIM_FLAG) }, 350)	// animation duration = 0.25
			this.__mobile_close_menu(wrapperElem, true)
		}
	}

	onClick_logo = (e) => {

		e.preventDefault()

		this.__closeMobileMenu()
		this.scrollUtils.scrollTop()
	}

	onClick_menuItem = (e) => {

		e.preventDefault()

		// console.log(">>>>>>>>> NavigationBar :: onClick_menuItem", e)
		this.scrollUtils.performOnClick(e)
		this.__closeMobileMenu()
	}

	__closeMobileMenu()
	{
		let wrapperElem = this.menuContainerRef.parentElement
		this.__mobile_close_menu(wrapperElem, true)
	}

	onClick_contactButton = (e) => {

		e.preventDefault()

		this.scrollUtils.performOnClick(e)
	}

	getContactHrefProps = () => {
		let resProps = {}

		try
		{
			for(let idx = 0; idx < this.props.children.length; idx++)
			{
				let each = this.props.children[idx]
				if ( each.style && each.data )
				{
					if ( each.type.toString().trim().toLowerCase() === "menuitem" )
					{
						if ( typeof(each.style.button_type)!=="undefined" && each.style.button_type!==null && each.style.button_type.trim().toLowerCase()==="contact" )
						{
							let href = each.data.href || ""
							if ( href.trim().length !== 0  )
							{
								resProps.href = href
								resProps.rel="nofollow noopener noreferrer"
							}
							else
							{
								resProps.href = "#" + (this.scrollUtils.getAnchorID(each)).id
							}
							if ( each.data.target ) { resProps.target = each.data.target }

							// console.log(">>>>>>>>>>>>>", resProps)
							return resProps
						}
					}
				}
			}
		}
		catch (err) 
		{
			console.log(`%cError :: `, 'color: #f00', err)
		}

		return resProps
	}

	render() 
	{
		// console.log(">>>>>>>>>> NavigationBar :: this.props=", this.props)
		
		let componentUtils = new ComponentUtils()
		let children = componentUtils.getMenuItems(this.props, (this.props.name | 'NavigationBar'), this.onClick_menuItem)

		let menuItems_default_arr = []
		let menuItem_contact = null
		for (let each of children)
		{
			let mi = each.props
			let button_type = "default"
			if ( typeof(mi.style)!="undefined" && mi.style!=null && typeof(mi.style.button_type)!="undefined" && mi.style.button_type!=null )
			{
				let type = mi.style.button_type
				if ( typeof(type)=="undefined" || type==null )
				{
					type = "default"
				}
				switch ( type.toLowerCase().trim() )
				{
					case "contact": button_type = "contact"; break
					default: button_type = "default"; break
				}
			}
			switch ( button_type )
			{
				case "contact":
					menuItem_contact = each
					break
				case "default":
				default:
					menuItems_default_arr.push( each )
					break
			}
		}

		let contact_mobileButton_href_props = this.getContactHrefProps()

		let classes = {
			// "navbar--bg-top": (this.state.visible && this.state.prevScrollPos<=0),
			// "navbar--bg": (this.state.visible && this.state.prevScrollPos>0),
		}

		return (
			<ModuleWrapper className={classnames("NavigationBar", classes)} {... this.props}>
				<div className="menu-container" ref={e => this.menuContainerRef = e}>
					<div className="menu-header">
						<a ref={e => this.logoRef = e} href="#app" onClick={this.onClick_logo} target="_blank" rel="nofollow noopener noreferrer">
							<img className="db-logo" src={image_DBLogo} alt="DB-Logo" />
						</a>
						<ul className="horizontal-list">
							{menuItems_default_arr.map((each, i) => { 
								return <li key={i}>{each}</li>
							})}
						</ul>
						{menuItem_contact != null
							? <div className="contact-item" key={`NavBarItem_contact${Math.round(Math.random() * 100000)}`}>{menuItem_contact}</div> 
							: ""
						}
						<div className="mobile-menu-button" ref={e => this.mobileMenuButtonRef = e}>
							<button className="contact-button" onClick={this.onClick_contactButton} {...contact_mobileButton_href_props}>
								<IconMobileMenuContact className="contact-icon" />
							</button>
							<button className="cta" onClick={this.onClick_mobileMenuButton}>
								{/* <img className="open" src={icon_mobileMenu_open} alt="Menü" /> */}
								<IconMobileMenuOpen className="open" />
								<img className="close" src={icon_mobileMenu_close} alt="Close" />
							</button>
						</div>
					</div>
					<div className="menu-body">
						<ul className="vertical-list">
							{children.map((each, i) => <li key={i}>{each}</li>)}
						</ul>
					</div>
				</div>
			</ModuleWrapper>
		)
	}
}

// NavigationBar.defaultProps = {
// 	name: "",
// 	primary: false,
// 	secondary: false,
// }

// NavigationBar.propTypes = {
// 	name: PropTypes.string,
// 	primary: PropTypes.bool,
// 	secondary: PropTypes.bool,
// }

export default NavigationBar