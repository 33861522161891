import React, { Component, lazy, Suspense } from 'react'
// import PropTypes from "prop-types"
import classnames from 'classnames'
import './VideoTop.scss'

// import config from "../../_config/config"
import PathUtils from '../../classes/PathUtils'

import ArrowDown from '../../assets/arrow_down.js'

const VideoComponent = lazy(() => import('./VideoComponent'))

class VideoTop extends Component 
{
	constructor(props) 
	{
		super(props)
		this.state = {
			isMobileView: false
		}
		this.videoRef = React.createRef
		this.restartVideo = false
	}

	componentDidMount()
	{
	}

	componentWillUnmount()
	{
	}

	getMediaSource(source)
	{
		/*
		response (NEW) := {
			...
			data: {
				videoSrc: {
					desktop: '.../..../video.mp4',
					mobile: '.../..../video.mp4'
				},
				videoPoster: {						// optional
					desktop: '.../poster.png',
					mobile: '.../poster.png'
				}
			},
			...
		}

		.... Kompatibilität zu der alten Version wird gewahrt, d.h. zu

		response (OLD) := {
			...
			data: {
				videoSrc: '.../..../video.mp4',
				videoPoster: '.../poster.png',	// optional
			},
			...
		}
		*/
		let res = source
		if ( typeof(source) === "object" )
		{
			res = ( this.state.isMobileView ? source.mobile : source.desktop ) || ""
		}
		return res
	}

	render() 
	{
		// console.log(">>>>>>>>>> VideoTop :: this.props=", this.props)

		let pathUtils = new PathUtils()
		let data = this.props.data || {}
		let cover = data.cover || {}

		let cover_color = cover.color || ""
		let cover_opacity = parseFloat( cover.opacity || "1.0" )
		let title = data.title || ""
		let subtitle = data.subtitle || ""
		let hasNoTitle = ( title.length<=0 && subtitle.length<=0 )
		// let decoBarSrc = pathUtils.resolveImagePath( this.getMediaSource( data.decorationBarSrc || "" ) )
		// let videoSrc = this.getMediaSource( data.videoSrc || "" ) ... siehe in VideoComponent.js
		let videoPoster = this.getMediaSource( data.videoPoster || "" )		// ... wird auch in VideoComponent.js gelesen!
		let posterSrc = pathUtils.resolveImagePath(videoPoster)

		return (
			<>
				<div className="VideoTop">
					<div className="video-container">
						{/* <video id="yt_video" ref={elem => (this.videoRef = elem)} poster={posterSrc} autoPlay muted loop playsInline>
							<source src={videoSrc} type="video/mp4" /> 
							<img src={posterSrc} alt="header" />
							Your browser does not support the video tag.
						</video> */}
						<img className={classnames("video-fallback")} src={posterSrc} alt="video fallback" />
						{/* <Suspense fallback={<div style={{padding: "100px 0 0 0", color: "#000", fontSize: "30px"}}>Loading ...</div>}> */}
						<Suspense fallback={<div></div>}>
							<VideoComponent {... this.props} />
						</Suspense>
						<div className="cover" style={{background: cover_color, opacity: cover_opacity}}></div>
					</div>
					<div className="content">
						<div className={classnames("title-main-container", {"hidden": hasNoTitle})}>
							<h1 dangerouslySetInnerHTML={{ __html: title }} />
							<h2 dangerouslySetInnerHTML={{ __html: subtitle }} />
						</div>
						<ArrowDown className="arrow-down" />
					</div>
				</div>
			</>
		)
	}
}

// VideoTop.defaultProps = {
// 	name: "",
// 	primary: false,
// 	secondary: false,
// }

// VideoTop.propTypes = {
// 	name: PropTypes.string,
// 	primary: PropTypes.bool,
// 	secondary: PropTypes.bool,
// }

export default VideoTop
