const config = {
	api_target: process.env.NODE_ENV,		// := [ "development", "production" ]
	api_config: {
		"development": {
			//baseURL: "http://jsonplaceholder.typicode.com/",
			// baseURL: "https://httpbin.org/get",			// https://httpbin.org/get?test_param=42
			// baseURL: "http://localhost:3030/api/",
			// -------- ok
			// baseURL: "http://localhost:3030/models",
			baseURL: "https://microsite-netzwerk.de/dbtf-landingpage/dbtf-api",
			// baseURL: "https://triebfahrzeugfuehrende.db.hogarthww.de/api/hww.php?",	// mit "?" am Ende damit der URL-Teil "page" nicht stört, wegen "http://localhost:3030/models/" + "page"
			"console.log": true
		},
		"production": {
			baseURL: window.apiurl,
			// baseURL: "https://triebfahrzeugfuehrende.db.hogarthww.de/api/hww.php?",	// mit "?" am Ende damit der URL-Teil "page" nicht stört, wegen "http://localhost:3030/models/" + "page"
			// baseURL: "https://staging.qosmo.training/api",			// hier ohne "/" am Ende! Das "/" wird im API-Call als URL angegeben! ACHTUNG! Die Anfrage an https://staging.qosmo.training/api/ immer mit abschließendes "/" angeben, sonst funktioniert die Anfrage nicht!
			"console.log": true
		}
	}
}

export default config
