import React from "react";

const Icon_nav_open = ({
//   style = {},
  fill = "#000",
//   width = "100%",
  className = "",
  viewBox = "0 0 22 16"
}) => (
	<svg 
		className={`svg-icon ${className || ""}`}
		xmlns="http://www.w3.org/2000/svg" 
		/*width="22" 
		height="16" */
		viewBox={viewBox}
	>
		<path 
			d="M21,16H1a1,1,0,0,1-1-1.016A.931.931,0,0,1,1,14H21a1,1,0,0,1,1,1.015A.932.932,0,0,1,21,16ZM17,9H1A1,1,0,0,1,0,7.984.931.931,0,0,1,1,7H17a1,1,0,0,1,1,1.015A.932.932,0,0,1,17,9Zm4-7H1A1,1,0,0,1,0,.985.932.932,0,0,1,1,0H21a1,1,0,0,1,1,1.016A.931.931,0,0,1,21,2Z" 
			transform="translate(0)" 
			fill={fill}
		/>
	</svg>
);

/*
<svg
    width={width}
    style={style}
    height={width}
    viewBox={viewBox}
    xmlns="http://www.w3.org/2000/svg"
    className={`svg-icon ${className || ""}`}
    xmlnsXlink="http://www.w3.org/1999/xlink"
>
	<defs>
		<style>
			{ `.cls-1{ fill: none; stroke: ${fill}; stroke-linecap: round; stroke-linejoin: round; stroke-width: 2px; }` }
		</style>
	</defs>
	<path
		fill={fill}
		d="M15 18.5l-5-5.7 1.4-1.3 3.6 4.1 3.6-4.1 1.4 1.3-5 5.7z"
	/>
</svg>
*/

export default Icon_nav_open;
