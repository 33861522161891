// inspired by https://leanpub.com/redux-book
// import axios from "axios"
import { API } from "../actions/types"
import { /*accessDenied,*/ apiError, apiStart, apiEnd } from "../actions/api"
import config from "../../_config/config"


const apiMiddleware = ({ dispatch }) => next => action => {

	if ( typeof(action)=="undefined" || action==null ) return	// Fix: Unhandled Rejection (Error): Actions must be plain objects. Use custom middleware for async actions.
	next(action)

	if ( action.type !== API ) return

	const api_config = config.api_config[config.api_target]
	const {
		url,
		// method,
		// data,
		// accessToken,
		onSuccess,
		onFailure,
		label,
		// headers
	} = action.payload

	if ( label ) 
	{
		if ( api_config["console.log"] )
		{
			console.log(`%c>>>>>>>>>> apiMiddleware :: api request - START`, "color: #f90;")
		}
		dispatch( apiStart(label) )
	}

	let api_url = api_config.baseURL + url
	if ( api_config["console.log"] )
	{
		console.log(`%c>>>>>>>>>> apiMiddleware :: %cbaseURL: ${api_config.baseURL}, url: ${url} %c=> api_url: ${api_url}`, "color: green;", "color: #999;", "color: green;")
	}

	let httpRequest = new XMLHttpRequest()
	if ( !httpRequest ) 
	{
		if ( api_config["console.log"] )
		{
			console.log(`%c>>>>>>>>>> apiMiddleware :: Error : Cannot create an XMLHTTP instance!`, "color: red;")
		}
		return false
	}
	httpRequest.onreadystatechange = (event) => {

		// console.log(">>>>>>>>>> apiMiddleware ::", event)
		
		if ( httpRequest.readyState === XMLHttpRequest.DONE ) 
		{
			if ( httpRequest.status === 200 ) 
			{
				let data = JSON.parse(httpRequest.responseText)
				if ( api_config["console.log"] )
				{
					console.log(`%c>>>>>>>>>> apiMiddleware :: OK `, "color: green;", data)
				}
				dispatch( onSuccess(data) )
			} 
			else 
			{
				const error = {
					response: event.currentTarget.response,
					responseText: event.currentTarget.responseText,
					url: event.currentTarget.responseURL
				}
				if ( api_config["console.log"] )
				{
					console.log(`%c>>>>>>>>>> apiMiddleware :: ERROR!`, "color: red;", error)
				}
				dispatch( apiError(error) )
				dispatch( onFailure(error) )
			}

			if ( label ) 
			{
				if ( api_config["console.log"] )
				{
					console.log(`%c>>>>>>>>>> apiMiddleware :: api request - END`, "color: #f90;")
				}
				dispatch( apiEnd(label) )
			}
		}
	}
	httpRequest.open('GET', api_url)
	httpRequest.send()
}


/*
const apiMiddleware = ({ dispatch }) => next => action => {

	if ( typeof(action)=="undefined" || action==null ) return	// Fix: Unhandled Rejection (Error): Actions must be plain objects. Use custom middleware for async actions.
	next(action)

	if ( action.type !== API ) return

	const api_config = config.api_config[config.api_target]
	const {
		url,
		method,
		data,
		accessToken,
		onSuccess,
		onFailure,
		label,
		headers
	} = action.payload
	const dataOrParams = ( ["GET", "DELETE"].includes(method) 
		? "params" 
		: "data" 
	)

	// axios default configs
	axios.defaults.baseURL = api_config.baseURL
	axios.defaults.headers.common["Content-Type"] = "application/json"
	axios.defaults.headers.common["Accept"] = "application/json"
	axios.defaults.headers.common["Authorization"] = `Bearer ${accessToken}`

	if ( label ) 
	{
		dispatch(apiStart(label))
	}

	// console.log(">>>>>>>>>> apiMiddleware:", url, api_config.baseURL)

	axios
		.request({
			url,
			method,
			headers,
			[dataOrParams]: data
		})
		.then(({ data }) => {
			if ( api_config["console.log"] )
			{
				console.log(">>>>>>>>>> apiMiddleware >> success :: data=", data, onSuccess(data))
			}
			dispatch(onSuccess(data))
		})
		.catch(error => {
			dispatch(apiError(error))
			dispatch(onFailure(error))

			if ( error.response && error.response.status === 403 ) 
			{
				dispatch(accessDenied(window.location.pathname))
			}
		})
		.finally(() => {
			if ( label ) 
			{
				dispatch(apiEnd(label))
			}
		})
}
*/

export default apiMiddleware
