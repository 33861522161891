
class LayoutUtils
{
	// constructor() {}

	parseMarginPaddingSize(value)
	{
		if ( typeof(value)!="undefined" && value!=null )
		{
			if ( /^\s*small\s*$/i.test(value) )
			{
				return "small"
			}
			else if ( /^\s*medium\s*$/i.test(value) )
			{
				return "medium"
			}
			else if ( /^\s*large\s*$/i.test(value) )
			{
				return "large"
			}
		}
		return ""
	}

	getMargin(prefix, props)
	{
		let res = ""

		try 
		{
			let margin = props.style.margin_top
			if ( typeof(margin)!="undefined" && margin!=null )
			{
				let mt = this.parseMarginPaddingSize(margin)
				if ( mt.length > 0 ) res += ` ${prefix}margin-top-${mt}`
			}
			margin = props.style.margin_bottom
			if ( typeof(margin)!="undefined" && margin!=null )
			{
				let mb = this.parseMarginPaddingSize(margin)
				if ( mb.length > 0 ) res += ` ${prefix}margin-bottom-${mb}`
			}
		}
		catch (err) {}

		return res.trim()
	}

	getPadding(prefix, props)
	{
		let res = ""

		try 
		{
			let padding = props.style.padding_top
			if ( typeof(padding)!="undefined" && padding!=null )
			{
				let pt = this.parseMarginPaddingSize(padding)
				if ( pt.length > 0 ) res += ` ${prefix}padding-top-${pt}`
			}
			padding = props.style.padding_bottom
			if ( typeof(padding)!="undefined" && padding!=null )
			{
				let pb = this.parseMarginPaddingSize(padding)
				if ( pb.length > 0 ) res += ` ${prefix}padding-bottom-${pb}`
			}
		}
		catch (err) {}

		return res.trim()
	}
}

export default LayoutUtils