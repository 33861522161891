
import ScrollOut from "scroll-out"

class ScrollOutUtils
{
	// constructor() {}

	static init()
	{
		ScrollOut({
			threshold: 0.5,
			once: true,
		})
	}
}

export default ScrollOutUtils