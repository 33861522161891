import React, { Component } from 'react'
import classnames from "classnames"
import PropTypes from "prop-types"		// ... falls .propTypes (siehe am Ende der Datei) verwendet werden

import PathUtils from '../../classes/PathUtils'
import ScrollUtils from "../../classes/ScrollUtils"
import './ContactItem.scss'

import Icon_phone from '../../assets/icon_phone_32.svg'
import Icon_phone_red from '../../assets/icon_phone_32red.png'
import Icon_mail from '../../assets/icon_mail_32.svg'
import Icon_mail_red from '../../assets/icon_mail_32red.png'
import Icon_teams from '../../assets/icon_teams_32.svg'
import Icon_teams_red from '../../assets/icon_teams_32red.png'


class ContactItem extends Component 
{
	constructor(props) 
	{
		super(props)
		this.state = {}

		this.contact_data = this.init_contact_data()
		this.scrollUtils = new ScrollUtils()
	}

	init_contact_data()
	{
		let data = this.props.data || {}
		let fullName = (data.contact.fullName || "").trim()
		let email = (data.contact.email || "").trim()
		if ( fullName.length>0 && email.length>0 )
		{
			return `${fullName}|${email}`
		}

		return ""
	}

	onClick_hoverImage = (e) => {
		console.log("++ onClick_hoverImage:", this.contact_data, this.props.contact_form_id)

		e.preventDefault()
		this.scrollUtils.scollToAnchorID( this.props.contact_form_id )

		if ( this.props.onContactPersonCB )
		{
			this.props.onContactPersonCB( this.contact_data )
		}
	}

	trackVal(e,i) {
		window._paq = window._paq || [];
		if (window._paq) {
			window._paq.push(['trackEvent', 'contact', i, e]);
		}
	}

	render() 
	{
		// console.log(">>>>>>>>>> ContactItem :: this.props=", this.props)

		if ( !this.props.onContactPersonCB )
		{
			console.error("################################################\nContactCols >> Error: this.props.onContactPersonCB not set!!!")
			return (<></>)
		}

		let pathUtils = new PathUtils()
		let data = this.props.data || {}

		let title = data.title || ""
		let subline = data.subline || ""
		let img_src = data.imgSrc || null
		let img_alt = data.imgAlt || "portrait"
		let fullName = data.contact.fullName || ""
		let phone = data.contact.phone || ""
		let phone_link = phone.replace(/\s+/g, "")
		let email = data.contact.email || ""
		let teamsUrl = data.contact.teamsUrl || ""
		let teamsLabel = data.contact.teamsLabel || "MS Teams Kontakt"

		let img = ""

		if ( img_src != null )
		{
			let src = pathUtils.resolveImagePath( img_src )
			img = <img className="hover-image" src={src} alt={img_alt} />
		}
		
		// https://medium.com/trabe/getting-rid-of-finddomnode-method-in-your-react-application-a0d7093b2660
		
		/* BACKUP - olld Version, OHNE Hover-Effekt. Hover funktioniert nicht mit diesem Layout */
		// <div className="img-container-wrapper">			{/* IE 11 Fix */}
		// 		<div className="img-container-wrapper-inner">
		// 		{img}
		// 	</div>
		// </div>

		return (
			<div className={classnames("ContactItem" /*, "col1"*/)} data-scroll>
				<div className="contact-hover-image" onClick={this.onClick_hoverImage}>
					{img}
				</div>
				<div className="content-container-wrapper">		{/* IE 11 Fix - darf kein display: flex enthalten */}
					<div className={classnames("content-container")}>
						<div className="title" dangerouslySetInnerHTML={{ __html: title }} />
						{ subline.length > 0
							? <div className="subline" dangerouslySetInnerHTML={{ __html: subline }} />
							: ""
						}
						<div className="full-name" dangerouslySetInnerHTML={{ __html: fullName }} />
						{ phone.length > 0
							?	//<div className="phone" dangerouslySetInnerHTML={{ __html: phone }} />
								<div className="phone">
									<a href={"tel:"+phone_link} target="_blank" rel="noreferrer" onClick={(e) => this.trackVal(phone_link,'call')}>
										<div className="icon">
											<img src={Icon_phone} className={classnames("icon-img", "icon-default")} alt="phone icon" />
											<img src={Icon_phone_red} className={classnames("icon-img", "icon-hover")} alt="phone icon" />
										</div>
										<span dangerouslySetInnerHTML={{ __html: phone }} />
									</a>
								</div>
							: ""
						}
						{ email.length > 0
							? (
								<div className="email">
									{/* <a href={"mailto:"+email} target="_blank" rel="noreferrer" dangerouslySetInnerHTML={{ __html: email }} /> */}
									<a href={"mailto:"+email} target="_blank" rel="noreferrer" onClick={(e) => this.trackVal(email,'mail')}>
										<div className="icon">
											<img src={Icon_mail} className={classnames("icon-img", "icon-default")} alt="mail icon" />
											<img src={Icon_mail_red} className={classnames("icon-img", "icon-hover")} alt="mail icon" />
										</div>
										<span dangerouslySetInnerHTML={{ __html: email }} />
									</a>
								</div>
							  )
							: ""
						}
						{ teamsUrl.length > 0
							? (
								<div className="teams">
									{/* <a href={teamsUrl} target="_blank" rel="noreferrer" dangerouslySetInnerHTML={{ __html: teamsLabel }} /> */}
									<a href={teamsUrl} target="_blank" rel="noreferrer">
										<div className="icon">
											<img src={Icon_teams} className={classnames("icon-img", "icon-default")} alt="teams icon" />
											<img src={Icon_teams_red} className={classnames("icon-img", "icon-hover")} alt="teams icon" />
										</div>
										<span dangerouslySetInnerHTML={{ __html: teamsLabel }} />
									</a>
								</div>
							  )
							: ""
						}
					</div>
				</div>
			</div>
		)
	}
}

ContactItem.defaultProps = {
// 	name: "",
// 	embedded: false,
// 	secondary: false,
	contact_form_id: -1,
	onContactPersonCB: null,
}

ContactItem.propTypes = {
// 	name: PropTypes.string,
// 	embedded: PropTypes.bool,
// 	secondary: PropTypes.bool,
	contact_form_id: PropTypes.number,
	onContactPersonCB: PropTypes.func,
}

export default ContactItem
