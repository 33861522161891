import React, { Component } from 'react'
import PropTypes from "prop-types"
import classnames from "classnames"
import './TestimonialItem.scss'

import PathUtils from '../../classes/PathUtils'


class TestimonialItem extends Component 
{
	constructor(props) 
	{
		super(props)
		this.state = {}
	}

	render() 
	{
		// console.log(">>>>>>>>>> TestimonialItem :: this.props=", this.props)

		let pathUtils = new PathUtils()
		let item = this.props.item || {}
		let data = item.data || {}
		
		let name = data.name || ""
		let position = data.position || ""
		let quote = data.quote || ""
		let imgSrc = pathUtils.resolveImagePath(data.imgSrc || "")
		let imgAlt = data.imgAlt || ""
		
		return (
			<div className="TestimonialItem">
				<div className="img-container-wrapper">			{/* IE 11 Fix */}
					<img className="image" src={imgSrc} alt={imgAlt} />
				</div>
				<div className="text-container-wrapper">		{/* IE 11 Fix - darf kein display: flex enthalten */}
					<div className={classnames("text-container", "unselectable")}>
						<div className="top-line">
							<div className="name">{name}</div>
							<div className="position">{position}</div>
						</div>
						<div className="quote">{quote}</div>
					</div>
				</div>
				<div className="top-line-mobile">
					<div className="name-mobile">{name}</div>
					<div className="position-mobile">{position}</div>
				</div>
				<div className="quote-mobile">{quote}</div>
			</div>
		)
	}
}

TestimonialItem.defaultProps = {
	itemIdx: 0,
	item: null,
}

TestimonialItem.propTypes = {
	itemIdx: PropTypes.number,
	item: PropTypes.object,
}

export default TestimonialItem
