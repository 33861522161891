
import MenuItem from "../components/MenuItem/MenuItem"

class ComponentUtils
{
	// constructor() {}

	getMenuItems = (props, moduleId, onClickCB = null) => {
		let children = []

		try
		{
			for(let idx = 0; idx < props.children.length; idx++)
			{
				let each = props.children[idx]
				if ( each.type && each.data )
				{
					if ( each.type.toString().trim().toLowerCase() === "menuitem" )
					{
						children.push( <MenuItem onClickCB={onClickCB} {... each} /> )
					}
					else
					{
						console.log(`%cWarning >> ${moduleId}: %cUnknown type "${each.type}"!\nchild:`, 'color: #f00', 'color: #f90', each)
					}
				}
			}
		}
		catch (err) 
		{
			console.log(`%cError :: `, 'color: #f00', err)
		}

		return children
	}
}

export default ComponentUtils