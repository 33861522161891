import React, { Component } from 'react'
import classnames from 'classnames'
import './BackgroundWrapper.scss'


class BackgroundWrapper extends Component 
{
	constructor(props) 
	{
		super(props)
		this.state = {}
	}

	getBackgroundImage(props)
	{ 
		// let starsBg = props.style.starsBackground

		// if ( /\s*top\s*/i.test(starsBg) )
		// {
		// 	return "stars-top"
		// }
		// else if ( /\s*bottom\s*/i.test(starsBg) )
		// {
		// 	return "stars-bottom"
		// }

		let style = props.style || {}
		let bgImg = style.backgroundImage || ""

		// if ( /\s*true\s*/i.test(showBgImg) )
		// {
		// 	return "show-bg-img"
		// }
		if ( /TL-BR/i.test(bgImg) ) return "bg-TL-BR"
		if ( /TR-BL/i.test(bgImg) ) return "bg-TR-BL"
		if ( /^\s*L\s*$/i.test(bgImg) ) return "bg-L"
		if ( /^\s*R\s*$/i.test(bgImg) ) return "bg-R"

		return ""		// default, if unknown starsBackground
	}

	render() 
	{
		// console.log(">>>>>>>>>> BackgroundWrapper :: this.props=", this.props)

		let divBgImage = this.getBackgroundImage(this.props)

		return (
			<div className={classnames('bg-wrapper', divBgImage)}>
				{this.props.children}
			</div>
		)
	}
}

// BackgroundWrapper.defaultProps = {
//     name: "",
//     primary: false,
//     secondary: false,
// }
//
// BackgroundWrapper.propTypes = {
//     name: PropTypes.string,
//     primary: PropTypes.bool,
//     secondary: PropTypes.bool,
// }

export default BackgroundWrapper
