
class EmailUtils
{
	constructor() 
	{
		this.validEmailDomains = [
			"deutschebahn.com",
			"ioki.com",
			"mobimeo.com",
			"dbschenker.com",
			"bahnbau-gruppe.de"
		]

		this.validEmailRegExp = []
		for(let each of this.validEmailDomains)
		{
			this.validEmailRegExp.push( new RegExp(`^.+@${each}\\s*$`, "i") )
		}
	}

	hasValidEmailDomain = (input_str) => {
		for(let each of this.validEmailRegExp)
		{
			if ( each.test(input_str) )
			{
				return true
			}
		}
		return false
	}
}

export default EmailUtils