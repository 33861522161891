import React, { Component } from 'react'
import classnames from "classnames"
// import PropTypes from "prop-types"		// ... falls .propTypes (siehe am Ende der Datei) verwendet werden

import CountUp from 'react-countup'
import VisibilitySensor from 'react-visibility-sensor'
import PathUtils from '../../classes/PathUtils'
import './FeatureNumberItems.scss'


class BoldNumberItem extends Component 
{
	constructor(props) 
	{
		super(props)
		this.state = {}
	}

	render() 
	{
		// console.log(">>>>>>>>>> BoldNumberItem :: this.props=", this.props)

		let pathUtils = new PathUtils()
		let data = this.props.data || {}

		let numberValue = data.number || 0
		let title = data.title || ""
		let subline = data.subline || null
		let icon_src = data.iconSrc || null
		let icon_alt = data.iconAlt || ""
		let icon = ""

		if ( icon_src != null )
		{
			let iconSrc = pathUtils.resolveImagePath( icon_src )
			icon = <img className="icon" src={iconSrc} alt={icon_alt} />
		}
		
		// https://medium.com/trabe/getting-rid-of-finddomnode-method-in-your-react-application-a0d7093b2660
		
		return (
			<div className={classnames("BoldNumberItem" /*, "col1"*/)} data-scroll>
				{icon}
				<CountUp 
					start={0} 
					end={numberValue} 
					delay={0} 
					duration={1.0}
					separator="."
					decimals={0}
					decimal=","
					prefix=""
					suffix=""
					// onEnd={() => console.log('Ended! 👏')}
					// onStart={() => console.log('Started! 💨')}
				>
					{({ countUpRef, start }) => (
						<VisibilitySensor 
							onChange={(isVisible) => { if ( isVisible ) start() }} 
							offset={{top: 100, bottom: 100}}
							delayedCall
						>
							<div ref={countUpRef} className="numValue" />
						</VisibilitySensor>
					)}
				</CountUp>
				<div className="title" dangerouslySetInnerHTML={{ __html: title }} />
				{ subline != null
					? <div className="subline" dangerouslySetInnerHTML={{ __html: subline }} />
					: ""
				}
			</div>
		)
	}
}

// BoldNumberItem.defaultProps = {
// 	name: "",
// 	embedded: false,
// 	secondary: false,
// }

// BoldNumberItem.propTypes = {
// 	name: PropTypes.string,
// 	embedded: PropTypes.bool,
// 	secondary: PropTypes.bool,
// }

export default BoldNumberItem
