import React, { Component } from 'react'
import PropTypes from "prop-types"
import classnames from "classnames"
import './HighlightItem.scss'

import PathUtils from '../../classes/PathUtils'


class HighlightItem extends Component 
{
	constructor(props) 
	{
		super(props)
		this.state = {}
	}

	render() 
	{
		// console.log(">>>>>>>>>> HighlightItem :: this.props=", this.props)

		let pathUtils = new PathUtils()
		let item = this.props.item || {}
		let data = item.data || {}
		
		let imgSrc = pathUtils.resolveImagePath( data.imgSrc || "" )
		let imgAlt = data.imgAlt || ""
		let description = data.description || ""
		
		return (
			<div className="HighlightItem">
				<div className="img-container-wrapper">			{/* IE 11 Fix */}
					<img className="image" src={imgSrc} alt={imgAlt} />
				</div>
				{ description.length > 0
					? (
						<div className="text-container-wrapper">		{/* IE 11 Fix - darf kein display: flex enthalten */}
							<div className={classnames("text-container")} dangerouslySetInnerHTML={{ __html: description }} />
						</div>
					  )
					: ""
				}
			</div>
		)
	}
}

HighlightItem.defaultProps = {
	itemIdx: 0,
	item: null,
}

HighlightItem.propTypes = {
	itemIdx: PropTypes.number,
	item: PropTypes.object,
}

export default HighlightItem
