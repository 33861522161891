import React, { Component } from 'react'
import PropTypes from "prop-types"
import classnames from "classnames"
import './Title.scss'

import ScrollableAnchor from 'react-scrollable-anchor'
// import ScrollOut from "scroll-out"
import ScrollOutUtils from "../../classes/ScrollOutUtils"
import ScrollUtils from "../../classes/ScrollUtils"
import ModuleWrapper from "../ModuleWrapper/ModuleWrapper"

import img_stars_bg from "../../assets/stars_bg.png"
import img_stars_bg_m from "../../assets/stars_bg_m.png"


class Title extends Component 
{
	constructor(props) 
	{
		super(props)
		this.state = {}
	}

	componentDidMount()
	{
		ScrollOutUtils.init()
	}

	getDecorationBar(props)
	{
		try 
		{
			let withDecoBg = props.style.withDecorationBar

			if ( /\s*true\s*/i.test(withDecoBg) )
			{
				return "show-deco-bar"
			}
		}
		catch (err)
		{}

		return ""		// default, if unknown withDecorationBar
	}

	render() 
	{
		// console.log(">>>>>>>>>> Title :: this.props=", this.props)
		
		let subtitle = ""
		let hasSubtitle = "without-subtitle"
		let withDecorationBar = this.getDecorationBar(this.props)
		let anchor_props = (new ScrollUtils()).getAnchorID(this.props)

		try
		{
			let str = this.props.data.subtitle.toString()
			if ( str.trim().length > 0 )
			{
				let additional_props = {}
				if ( !this.props.embedded || (this.props.embedded && this.props.animated) )
				{
					additional_props["data-scroll"] = true
				}
				subtitle = <p className="subtitle" {... additional_props} dangerouslySetInnerHTML={{ __html: str }}></p>
				hasSubtitle = "with-subtitle"
			}
		}
		catch (error)
		{}

		if ( this.props.embedded )
		{
			let additional_props = {}
			if ( this.props.animated )
			{
				additional_props["data-scroll"] = true
			}
			return (
				<ScrollableAnchor {... anchor_props}>
					<div className={classnames("Title", "embedded")}>
						<div className="title-container">
							<h2 className={hasSubtitle} {... additional_props} dangerouslySetInnerHTML={{ __html: this.props.data.title }}></h2>
							<div className="subtitle-container">{subtitle}</div>
						</div>
					</div>
				</ScrollableAnchor>
			)
		}
		
		return (
			<ModuleWrapper className={classnames("Title", withDecorationBar)} {... this.props}>
				<div className={classnames("deco-bar", withDecorationBar)}>
					<img src={img_stars_bg} alt="decoration bar" className="desktop" />
					<img src={img_stars_bg_m} alt="decoration bar" className="mobile" />
					<div className="bar-container">
						<div className="bar-left"></div>
						<div className="bar-middle"></div>
						<div className="bar-right"></div>
					</div>
				</div>
				<ScrollableAnchor {... anchor_props}>
					<div className="title-container">
						<h2 className={hasSubtitle} dangerouslySetInnerHTML={{ __html: this.props.data.title }} data-scroll></h2>
						<div className="subtitle-container">{subtitle}</div>
					</div>
				</ScrollableAnchor>
			</ModuleWrapper>
		)
	}
}

Title.defaultProps = {
	// name: "",
	embedded: false,
	animated: true,			// wird nur beachtet, wenn embedded == true !!!
	// secondary: false,
}

Title.propTypes = {
	// name: PropTypes.string,
	embedded: PropTypes.bool,
	animated: PropTypes.bool,
	// secondary: PropTypes.bool,
}

export default Title
