
import { API_SET_RESPONSE, API_START, API_END, API_GET_REQUEST, API_ERROR, API_ACCESS_DENIED } from "../actions/types";
  
const main = (state = {}, action) =>
{
	// console.log("reducers/index.js :: action type => ", action.type);
	switch (action.type) 
	{
		case API_SET_RESPONSE:
			return { data: action.payload }
		
		case API_ACCESS_DENIED:
			return { error: action.payload }

		case API_ERROR:
			// console.log(">>>>>>>>>>>>>>>>> reducers/index.js :: action=", action, ", state=", state)
			return { error: action.error }

		case API_START:
			if (action.payload === API_GET_REQUEST) 
			{
				return {
					...state,
					isLoadingData: true
				};
			}
			break;

		case API_END:
			if (action.payload === API_GET_REQUEST) 
			{
				return {
					...state,
					isLoadingData: false
				};
			}
			break;

		default:
			return state;
	}
}

export default main;