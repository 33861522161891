import React from 'react';

import './Impressum.scss';

class Impressum extends React.Component 
{
	constructor(props) 
	{
		super(props);
		this.state = {};
	}

	render() 
	{
		return (<div className="Impressum">Impressum</div>)
	}
}

/*
const mapStateToProps = (state, ownProps) => ({
	state: state,
	language: state.toggleLanguage,
	cookies: ownProps.cookies
});
export default connect(mapStateToProps, { toggleLanguage })(Impressum)
*/
export default Impressum;