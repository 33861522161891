import { API_SET_RESPONSE, API, API_GET_REQUEST } from "./types";

export const api_get_request = (url, params) => {
	// console.log(">>>>>>>>>>>>>>>> api_get_request ::", url, params)
	return api_request_action({
		url: url,
		onSuccess: setAPIResponsePayload,
		onFailure: () => {console.log("Error occured loading data")},
		label: API_GET_REQUEST,
		data: params
	})
}
  
function setAPIResponsePayload(data) 
{
	return {
		type: API_SET_RESPONSE,
		payload: data
	};
}

function api_request_action({
	url = "",
	method = "GET",
	data = null,
	onSuccess = () => {},
	onFailure = () => {},
	label = ""
}) 
{
	return {
		type: API,
		payload: {
			url,
			method,
			data,
			onSuccess,
			onFailure,
			label
		}
	};
}