import React from "react";

const Icon_nav_arrow_back = ({
//   style = {},
  fill = "#000",
//   width = "100%",
  className = "",
  viewBox = "0 0 20 18"
}) => (
	<svg 
		className={`svg-icon ${className || ""}`}
		xmlns="http://www.w3.org/2000/svg" 
		viewBox={viewBox}
	>
		<defs>
			<style>
				{ `.cls-1{ fill: none; stroke: ${fill}; stroke-linecap: round; stroke-linejoin: round; stroke-width: 2px; }` }
			</style>
		</defs>
		<polyline className="cls-1" points="9 1 1 9 9 17 1 9 19 9"/>
	</svg>
);

/*
<svg
    width={width}
    style={style}
    height={width}
    viewBox={viewBox}
    xmlns="http://www.w3.org/2000/svg"
    className={`svg-icon ${className || ""}`}
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <path
      fill={fill}
      d="M15 18.5l-5-5.7 1.4-1.3 3.6 4.1 3.6-4.1 1.4 1.3-5 5.7z"
    />
  </svg>
  */

export default Icon_nav_arrow_back;
