import React from "react";

const Icon_nav_open = ({
//   style = {},
  fill = "#ec0016",
//   width = "100%",
  className = "",
  viewBox = "0 0 28 28"
}) => (
	<svg 
		className={`svg-icon ${className || ""}`}
		xmlns="http://www.w3.org/2000/svg" 
		/*width="28" 
		height="28" */
		viewBox={viewBox}
	>
		<path
			d="M.781,28A.924.924,0,0,1,0,27V10A3.005,3.005,0,0,1,.844,7.9,2.964,2.964,0,0,1,3,7H4A1,1,0,1,1,4,9H3a.99.99,0,0,0-1,1V24.593l2.719-2.718A2.779,2.779,0,0,1,6.625,21H16a.99.99,0,0,0,1-1V19a1,1,0,1,1,2,0v1a2.95,2.95,0,0,1-2.812,3H6.844a1.045,1.045,0,0,0-.718.281l-4.219,4.25A1.7,1.7,0,0,1,.781,28Zm26.187-7a.939.939,0,0,1-.688-.281l-4.406-4.438A.967.967,0,0,0,21.188,16H10a3,3,0,0,1-3-3V3a3,3,0,0,1,3-3H25a3,3,0,0,1,3,3V20A1.019,1.019,0,0,1,26.969,21ZM10,2A.989.989,0,0,0,9,3V13a.99.99,0,0,0,1,1H21.188a2.945,2.945,0,0,1,2.093.875L26,17.594V3a.972.972,0,0,0-.875-1Z" 
			fill={fill}
		/>
  </svg>
);

/*
<svg
    width={width}
    style={style}
    height={width}
    viewBox={viewBox}
    xmlns="http://www.w3.org/2000/svg"
    className={`svg-icon ${className || ""}`}
    xmlnsXlink="http://www.w3.org/1999/xlink"
>
	<defs>
		<style>
			{ `.cls-1{ fill: none; stroke: ${fill}; stroke-linecap: round; stroke-linejoin: round; stroke-width: 2px; }` }
		</style>
	</defs>
	<path
		fill={fill}
		d="M15 18.5l-5-5.7 1.4-1.3 3.6 4.1 3.6-4.1 1.4 1.3-5 5.7z"
	/>
</svg>
*/

export default Icon_nav_open;
